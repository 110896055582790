import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { H6, PSmall, A } from '../../../assets/elements';

const MessageBanner = () => {

  const gotoLink = () => {
    window.location.href = 'https://www.p4d.co.uk/christmas-delivery-times'
  }
  let message = '';
  const now = moment();
  if (now.isBefore(moment('2024-12-21'))) {
    message = 'Christmas Delivery. Last collection date for pre Christmas delivery is Friday, 20th December.';
  } else if (now.isBefore(moment('2025-01-02'))) {
    message = 'The next available collection is Thursday, 2nd January.';
  } else if (now.isBefore(moment('2022-01-03'))) {
    message = 'There will be no collection available between 31/12 - 03/01. Your next available date will be Tuesday 4th January.';
  }
  if (message) {
    return (
      <Wrapper>
        <StyledText>
          {message}
          {' '}
          <A onClick={gotoLink}>View more</A>
        </StyledText>
      </Wrapper>
    );
  } else {
    return null
  }
};

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }: any) => theme.colors.White};
  border-radius: 9px;
  border: 2px solid ${({ theme }: any) => theme.colors.Secondary};
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

const StyledH6 = styled(H6)`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 34px;
  color: ${({ theme }: any) => theme.colors.Black};
`;

const StyledText = styled(PSmall)`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }: any) => theme.colors.Black};
`;

export default MessageBanner;
